var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "settings-header-text" }, [
        _vm._v(" Settings "),
      ]),
      _c(
        "b-container",
        { staticStyle: { "max-width": "80%", padding: "15px" } },
        [
          _c("div", { staticClass: "row row-cols-1 row-cols-md-3" }, [
            _c(
              "div",
              { staticClass: "col mb-4" },
              [
                _c(
                  "b-card",
                  { staticClass: "text-center", attrs: { header: "Users" } },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c("b-icon", {
                          staticClass: "mr-2",
                          attrs: {
                            variant: "info",
                            "font-scale": "5",
                            icon: "person-fill",
                          },
                        }),
                        _c("br", { staticClass: "mb-4" }),
                        _c(
                          "router-link",
                          {
                            staticClass: "stretched-link",
                            attrs: { to: "/users/" },
                          },
                          [_vm._v("Add or manage users")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col mb-4" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "text-center",
                    attrs: { "header-bg-variant": "white", header: "Groups" },
                  },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c("b-icon", {
                          staticClass: "mr-2",
                          attrs: {
                            variant: "info",
                            "font-scale": "5",
                            icon: "people-fill",
                          },
                        }),
                        _c("br", { staticClass: "mb-4" }),
                        _vm._v(" Add or manage groups "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col mb-4" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "text-center",
                    attrs: { header: "Cronjob Management" },
                  },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c("b-icon", {
                          staticClass: "mr-2",
                          attrs: {
                            variant: "info",
                            "font-scale": "5",
                            icon: "clock",
                          },
                        }),
                        _c("br", { staticClass: "mb-4" }),
                        _c(
                          "router-link",
                          {
                            staticClass: "stretched-link",
                            attrs: { to: { name: "cronjobs-data" } },
                          },
                          [_vm._v("Manage Cronjobs")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col mb-4" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "text-center",
                    attrs: { header: "Fee Schedule Data" },
                  },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c("b-icon", {
                          staticClass: "mr-2",
                          attrs: {
                            variant: "info",
                            "font-scale": "5",
                            icon: "box-arrow-in-left",
                          },
                        }),
                        _c("br", { staticClass: "mb-4" }),
                        _c(
                          "router-link",
                          {
                            staticClass: "stretched-link",
                            attrs: { to: { name: "fee-schedule-data" } },
                          },
                          [_vm._v("Upload fee schedules")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col mb-4" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "text-center",
                    attrs: { header: "Taxonomy Code Data" },
                  },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c("b-icon", {
                          staticClass: "mr-2",
                          attrs: {
                            variant: "info",
                            "font-scale": "5",
                            icon: "journal-medical",
                          },
                        }),
                        _c("br", { staticClass: "mb-4" }),
                        _c(
                          "router-link",
                          {
                            staticClass: "stretched-link",
                            attrs: { to: { name: "taxonomy-code-data" } },
                          },
                          [_vm._v("Upload taxonomy codes")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col mb-4" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "text-center",
                    attrs: { header: "Payers Holding Tank" },
                  },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c("b-icon", {
                          staticClass: "mr-2",
                          attrs: {
                            variant: "info",
                            "font-scale": "5",
                            icon: "box-arrow-in-left",
                          },
                        }),
                        _c("br", { staticClass: "mb-4" }),
                        _c(
                          "router-link",
                          {
                            staticClass: "stretched-link",
                            attrs: { to: { name: "payers-data" } },
                          },
                          [_vm._v("Manage payers data")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }